import { defineNuxtRouteMiddleware } from '#imports'
import { KeycloakService } from '~/service/keycloak'

export default defineNuxtRouteMiddleware(async (to) => {
  if (!(window && window.location)) {
    console.warn('KeycloakService: window.location is not available, returning null')
    return
  }

  // ensure user is logged in through keycloak
  const authenticated = await KeycloakService.getInstance().isAuthenticated()
  if (!authenticated) {
    await KeycloakService.getInstance().login()
    throw new Error('User not authenticated')
  }
})
